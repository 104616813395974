import React from "react";
import "../App.css";
import logo from "../images/logo.png";
import policy from "../images/Brooks_Health_and_Care_Complaints_Policy.pdf";
import footerunderline from "../images/footer_underline.png";
import footerarrow from "../images/footerarrow.png";
import homesegment8bgIMG from "../images/newhomesegment8bgIMG.jpg";
import uprightarrow from "../images/uprightarrow.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="pb-3">
     
        <div className="footersection px-md-5 px-3 ">
          <div className="footersection1">
            <a href="/" className="footerlogo">
              <img src={logo} alt="logo" className="footerlogoo" />
            </a>
            <div className="logodesc py-4">
              We are your trusted companion in delivering empathetic, extensive
              and tailored home health care services.{" "}
            </div>
            <a
              href={policy}
              target="_blank"
   rel="noopener noreferrer"
              className="complaintpolicylink"
            >
              View Our Complaint Policy
            </a>

            <div className="social-media-icons pt-4">
              <a
                href="https://www.facebook.com/profile.php?id=61557096390057"
                className="social-media-icon"
              >
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              {/* <div className="social-media-icon">
                <i class="fa-brands fa-twitter"></i>{" "}
              </div> */}
              <a
                href="https://api.whatsapp.com/send?phone=447449367921"
                className="social-media-icon"
              >
                <i class="fa-brands fa-whatsapp"></i>
              </a>
              <a
                href="https://www.instagram.com/brookshealthandcareltd?igsh=ajV4YjVhZ2Exc2tp&utm_source=qr"
                className="social-media-icon"
              >
                <i class="fa-brands fa-instagram "></i>{" "}
              </a>
              <a
                href="https://brookshealthandcare.com/"
                className="social-media-icon"
              >
                <i class="fa-solid fa-globe "></i>{" "}
              </a>
              {/* <div className="social-media-icon ">
                <i class="fa-brands fa-linkedin-in "></i>{" "}
              </div> */}
            </div>
          </div>
          <div className="footersection2">
            <div className="footersection2title">Quick links</div>
            <div className="footerunderline">
              <img src={footerunderline} alt="underline" />
            </div>
            <div className="footersection2links pt-4">
              <div className="pb-md-3 pb-1">
                <a href="/" className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  Home
                </a>
              </div>
              <div className="pb-md-3 pb-1">
                <a href="#about-us" className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  About us
                </a>
              </div>
              <div className="pb-md-3 pb-1">
                <a href="#services" className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  Services
                </a>
              </div>
              <div className="pb-md-3 pb-1">
                <a href="#benefits" className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  Benefits
                </a>
              </div>
              <div className="pb-3">
                <a href="#contact" className="footersection2link">
                  <span className="footerarrow pe-1 ">
                    {" "}
                    <img src={footerarrow} alt="arrow" />{" "}
                  </span>{" "}
                  Contact
                </a>
              </div>
            </div>
          </div>
          <div className="footersection2">
            <div className="footersection2title">Services</div>
            <div className="footerunderline">
              <img src={footerunderline} alt="underline" />
            </div>
            <div className="footersection2links pt-4">
              <div className="footersection2link">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <img src={footerarrow} alt="arrow" />{" "}
                </span>{" "}
                Home Care Support
              </div>
              <div className="footersection2link">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <img src={footerarrow} alt="arrow" />{" "}
                </span>{" "}
                Live in Care
              </div>
              <div className="footersection2link">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <img src={footerarrow} alt="arrow" />{" "}
                </span>{" "}
                Wake in Nights
              </div>
              <div className="footersection2link">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <img src={footerarrow} alt="arrow" />{" "}
                </span>{" "}
                Companionship
              </div>
              <div className="footersection2link">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <img src={footerarrow} alt="arrow" />{" "}
                </span>{" "}
                Community Aid
              </div>
            </div>
          </div>
          <div className="footersection2">
            <div className="footersection2title">Contact</div>
            <div className="footerunderline">
              <img src={footerunderline} alt="underline" />
            </div>
            <div className="footersection2links pt-4">
              <div className="footersection2link">
                <span className="footerarrow pe-1 ">
                  <i class="fa-solid fa-location-dot"></i>
                </span>{" "}
                Juniper walk, Kempston, Bedford. MK42 7SX
              </div>
              <Link
                to={`mailto:admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
                className="footersection2link"
              >
                <span className="footerarrow pe-1 ">
                  {" "}
                  <i class="fa-solid fa-envelope"></i>
                </span>{" "}
                admin@brookshealthandcare.com
              </Link>
              <div className="footersection2link pt-3">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <i class="fa-solid fa-phone-volume"></i>
                </span>{" "}
                <a className="tellink" href="tel:+441234954004">
                  01234954004
                </a>
              </div>
              <div className="footersection2link pb-3">
                <span className="footerarrow pe-1 ">
                  {" "}
                  <i class="fa-brands contactfooterwhatsapp fa-whatsapp "></i>{" "}
                </span>{" "}
                <a className="tellink" href="https://api.whatsapp.com/send?phone=447449367921">
                07449367921
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright  text-center ">
          <div>
            Copyright © 2024 Brooks Health and Care Limited. All rights
            reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
