
import './App.css';
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './pages/home';





 

 

function App() {
  return (
    <BrowserRouter>
      <div className='app'>
            <Navbar/>
           
      <Routes>
      <Route path="/" element={<Home />} />
       
           </Routes>
      <Footer/>
      </div>
      </BrowserRouter>
    

  );
}

export default App;
