import "../App.css";
import React, { useState } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import menu from "../images/newmenu.svg";
import uprightarrow from "../images/uprightarrow.png";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="navbar fixed-top navbar-expand-lg ps-4 pe-2">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img className="logo navlogo" src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleMenuToggle}
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{ backgroundImage: `url(${menu})` }}
            ></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item">
                <a
                  className="nav-link pt-4 active ps-4"
                  aria-current="page"
                  href="/"
                  onClick={handleMenuItemClick}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pt-4 ps-4"
                  href="#about-us"
                  onClick={handleMenuItemClick}
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pt-4 ps-4"
                  href="#services"
                  onClick={handleMenuItemClick}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pt-4 ps-4"
                  href="#benefits"
                  onClick={handleMenuItemClick}
                >
                  Benefits
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pt-4 ps-4"
                  href="#testimonials"
                  onClick={handleMenuItemClick}
                >
                  Testimonials
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pt-4 ps-4"
                  href="#contact"
                  onClick={handleMenuItemClick}
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <Link
                  to={`mailto: admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
                  className="nav-link ps-4"
                >
                  <button className="testimonialbtn">
                    <span className="pe-2">Email Us</span>{" "}
                    <i class="fa-solid fa-envelope navenvelope"></i>
                  </button>
                </Link>
              </li>
              {/* Additional nav items here */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
