import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import checkbox from "../images/path.png";
import section1img1 from "../images/frame1.png";
import section1img2 from "../images/frame2.png";
import section1img3 from "../images/frame3.png";
import section1img4 from "../images/frame4.png";
import section2img from "../images/section2img.png";
import uprightarrow from "../images/uprightarrow.png";
import ScrollAnimation from "react-animate-on-scroll";
import section4img from "../images/section4img.png";
import section6img from "../images/section6img.png";
import star from "../images/star.png";

function Home() {
  return (
    <div>
      <section className="section1 ">
        <div>
          <div className="lhsheading">
            {" "}
            <span class="thick-dot"></span>{" "}
            <span className="ps-1"> A HOME AWAY FROM HOME FEEL</span>{" "}
          </div>
          <div className="section1exp pt-2 pt-md-1">
            Experience{" "}
            <span className="section1expblue">Ultimate Relaxation </span> at
            Brooks
          </div>
          <div className="section1paragraph pt-3">
            Experience the ultimate relaxation and rejuvenation with Brooks
            Massage & Wellness Haven. We bring our exceptional massage services
            to your doorstep, providing a tranquil escape in the comfort of your
            home.
          </div>
          <div className="section1list pt-md-5 pt-3">
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Experienced and Skilled Therapists{" "}
            </div>
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Personalized Massage Plans{" "}
            </div>
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Serene and Calming Environment{" "}
            </div>
            <div className="section1listname pb-2">
              {" "}
              <span className="pe-2">
                <img src={checkbox} alt="checkbox" />
              </span>{" "}
              Comprehensive Wellness Approach{" "}
            </div>
          </div>
          <div className="section1quote pt-md-5 pt-4">
            "The relaxation massage at Brooks is simply divine. I always leave
            feeling completely rejuvenated."{" "}
          </div>
        </div>
        <div>
          <div className="section1galleryi pb-2">
            <div>
              <img className="lpimg" src={section1img1} alt="" />
            </div>
            <div>
              <img className="lpimg" src={section1img2} alt="" />
            </div>
          </div>
          <div className="section1galleryii">
            <div>
              <img  className="lpimg" src={section1img3} alt="" />
            </div>
            <div>
              <img className="lpimg" src={section1img4} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section2 p-md-5 p-3" id="about-us">
        <div className="p">
          <img className="lpimg" src={section2img} alt="" />
        </div>
        <div>
          <div className="lhsheading lhsheading2">
            {" "}
            <span className=""> ABOUT US </span>{" "}
          </div>
          <div className=" section2exp">
            A Warm Welcome to Brooks
            <span className="section1expblue"> Massage </span> &{" "}
            <span className="section1expblue"> Wellness </span>Haven
          </div>
          <div className="section2paragraph py-3">
            At Brooks Massage & Wellness Haven, we are dedicated to enhancing
            your well-being through a variety of therapeutic massage services.
            Our professional therapists bring their expertise to you, ensuring a
            personalized and soothing experience right in your home. Our mission
            is to provide exceptional care that rejuvenates your body and calms
            your mind, allowing you to achieve optimal wellness.
          </div>
          <div className="section2missionbox p-3 rounded ">
            <div className="section2mission">Mission</div>
            <div className=" rounded section2missionpara p-3">
              To rejuvenate your body and calm your mind through exceptional
              massage services, fostering an environment of peace and
              well-being.
            </div>
          </div>
          <div className="section2missionbox p-3 rounded ">
            <div className="section2mission">Vision</div>
            <div className=" rounded section2missionpara p-3">
              To be the leading provider of home-based massage therapy, known
              for our exceptional service, professional therapists, and
              commitment to client satisfaction.{" "}
            </div>
          </div>
        </div>
      </section>

      <section className="section3 p-md-5 p-3" id="services">
        <div className="section3headingcontainer">
          <div>
            <div className="lhsheading lhsheading2">
              {" "}
              <span className=""> OUR SERVICES </span>{" "}
            </div>
            <div className=" section2exp">
              <span className="section1expblue"> Explore </span> Our Massage
              Services
            </div>
          </div>
          <div>
            <div className="section3headingpara">
              We offer a variety of massage services designed to meet your
              unique needs, all provided in the comfort of your home.
            </div>
            <div className="pt-3">
              <a href="#contact" className="testimonialbtn">
                <span className="pe-2">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </a>
            </div>{" "}
          </div>
          <div></div>
        </div>

        <div className="aboutsegment3container">
          <ScrollAnimation
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="aboutsegment3icon fa-solid fa-droplet"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Lymphatic Drainage Massage
            </div>
            <div className="aboutsegment3boxdesc">
              Boost your lymphatic system and reduce swelling with our gentle
              lymphatic drainage massage. This specialized technique enhances
              fluid circulation, helping to detoxify the body and improve immune
              function.
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid aboutsegment3icon fa-heart"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Deep Tissue Massage
            </div>
            <div className="aboutsegment3boxdesc">
              Relieve chronic muscle tension and pain with our therapeutic deep
              tissue massage. Our skilled therapists target deeper layers of
              muscle and connective tissue to alleviate stiffness and promote
              overall muscle health.
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-people-group aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">Relaxation Massage</div>
            <div className="aboutsegment3boxdesc">
              Indulge in pure relaxation and unwind with our soothing relaxation
              massage. Our gentle techniques are designed to calm the nervous
              system, reduce stress levels, and promote a deep sense of
              tranquility and peace.
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-address-card aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">
              Head and Shoulder Massage{" "}
            </div>
            <div className="aboutsegment3boxdesc">
              Ease tension and stress with our focused head and shoulder
              massage. This service is perfect for relieving headaches, neck
              pain, and shoulder stiffness, providing you with a sense of
              clarity and relaxation.
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-star aboutsegment3icon"></i>
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">Full Body Massage </div>
            <div className="aboutsegment3boxdesc">
              Experience complete relaxation and revitalization with our full
              body massage. This comprehensive treatment addresses all major
              muscle groups, enhancing circulation, reducing stress, and
              promoting overall physical and mental well-being.
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className="aboutsegment3box"
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div>
              <i class="fa-solid fa-bed aboutsegment3icon"></i>{" "}
            </div>
            <div className="aboutsegment3boxtitle py-md-3 py-2">Palliative Massage</div>
            <div className="aboutsegment3boxdesc">
              Palliative massage is designed to provide comfort and relief for
              individuals dealing with serious illnesses or end-of-life
              conditions. Our therapists specialize in gentle, supportive touch
              that helps reduce pain, anxiety, and other symptoms associated
              with chronic and terminal illnesses.
            </div>
          </ScrollAnimation>
        </div>
      </section>

      <section className="section4 p-md-5 p-3" id="benefits">
        <div className="section4heading text-center">
          <div className="lhsheading section4headingi lhsheading2">
            {" "}
            <span className=""> BENEFITS </span>{" "}
          </div>{" "}
          <div className=" section2exp section4headingii">
            Why <span className="section1expblue"> Choose </span> Brooks Massage
            & Wellness Haven?
          </div>
        </div>

        <div className="section4grid pt-4">
          <div className="pt-4">
            <img className="lpimg" src={section4img} alt="" />
          </div>
          <div>
            <div className="pb-2">
              <div className="section2missionbox p-3 rounded ">
                <div className="section2mission">Experienced Therapists</div>
                <div className=" rounded section2missionpara p-3">
                  Our skilled therapists are trained to provide the best massage
                  techniques tailored to your needs.
                </div>
              </div>
            </div>
            <div className="pb-2">
              <div className="section2missionbox p-3 rounded ">
                <div className="section2mission">Serene Environment</div>
                <div className=" rounded section2missionpara p-3">
                  Enjoy your massage in a calming, peaceful atmosphere designed
                  for your comfort.
                </div>
              </div>
            </div>
            <div className="pb-2">
              <div className="section2missionbox p-3 rounded ">
                <div className="section2mission">Personalized Care</div>
                <div className=" rounded section2missionpara p-3">
                  We tailor each session to your specific requirements, ensuring
                  maximum benefit and relaxation.
                </div>
              </div>
            </div>
            <div className="section2missionbox p-3 rounded ">
              <div className="section2mission">Holistic Approach</div>
              <div className=" rounded section2missionpara p-3">
                We focus on your overall well-being, addressing both physical
                and mental stress.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section5 p-md-5 p-3" id="testimonials">
        <div className="section3headingcontainer px-4">
          <div>
            <div className="lhsheading lhsheading2">
              {" "}
              <span className=""> TESTIMONIALS </span>{" "}
            </div>
            <div className=" section2exp">
              What Our <span className="section1expblue">Clients</span> Say
            </div>
          </div>
          <div>
            <div className="section3headingpara">
              We offer a variety of massage services designed to meet <br />
              your unique needs, all provided in the comfort of your home.
            </div>
            <div className="pt-3">
              <a href="#contact" className="testimonialbtn">
                <span className="pe-2">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </a>
            </div>{" "}
          </div>
          <div></div>
        </div>
        <div className="testimonials pt-3">
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “I found Brooks through a link while in the hospital in Turkey,
              where I had my BBL done. Upon my return to Northampton, I needed a
              masseur to come to my location. Brooks arranged a package for me
              from Turkey, and I was pleasantly surprised to find out that I
              could receive a Lymphatic massage at my house twice a week. The
              fact that their prices are reasonable made me feel financially
              comfortable.”
            </div>
            <div className="testimonialperson pt-4 d-flex">
              <div></div>
              <div className="pt-3">
                <div className="testimonialname"> Client 1 </div>
                {/* <div className="testimonialplace">USA, California</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “After hearing about Brooks from a friend, I decided to try their
              head and shoulder massage. It was amazing, and the home service
              made it even better. This has become an essential part of my
              monthly relaxation therapy, helping me unwind and destress. The
              therapists are very professional and create a calming environment,
              which makes the experience even more enjoyable.”
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname">Client 2</div>
                {/* <div className="testimonialplace">USA, Florida</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “As a busy professional, I needed a reliable service that could
              come to my home. Brooks has been a home away from home for me.
              Their deep tissue massage is now part of my monthly relaxation
              therapy, helping me maintain my overall health and well-being. The
              therapists are incredibly skilled and always ensure that I feel
              relaxed and rejuvenated after each session.”
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname">Client 3</div>
                {/* <div className="testimonialplace">USA, Nevada</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “Brooks Health and Care is responsible for my healthcare package.
              When I learned that they offer massage and wellness services, I
              was delighted to book a session right away. I've made it a part of
              my bi-weekly routine because, despite my age, I still appreciate
              the luxury of a good massage for my physical and
              mental well-being.”
            </div>
            <div className="testimonialperson pt-4 d-flex">
              <div></div>
              <div className="pt-3">
                <div className="testimonialname"> Client 4</div>
                {/* <div className="testimonialplace">USA, California</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “I recently booked a full body massage with Brooks, and the
              experience was exceptional. The convenience of home service and
              the professional care provided have made it a staple in my monthly
              relaxation therapy. The therapists are attentive to my needs, and
              I always feel completely relaxed and revitalized after each
              session. ”
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname">Client 5 </div>
                {/* <div className="testimonialplace">USA, Florida</div> */}
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="testimonialrating">
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
              <img src={star} alt="star" />
            </div>
            <div className="testimonialparagraph pt-3">
              “Foot reflexology at Brooks Massage & Wellness Haven is a game
              changer. The targeted pressure points help improve my overall
              health and reduce pain. The convenience of home service and the
              results make it a vital part of my monthly relaxation therapy. The
              therapists are highly knowledgeable and always ensure that I
              receive the best care possible. ”
            </div>
            <div className="testimonialperson pt-4 ">
              <div className="pt-3">
                <div className="testimonialname">Client 6</div>
                {/* <div className="testimonialplace">USA, Nevada</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section6 p-md-5 p-3"
        style={{
          backgroundImage: `url(${section6img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          backgroundColor: "rgb(0 0 0 / 70%)",
          position: "relative",
        }}
      >
        <div>
          <div className="section2exp section6heading">
            Ready to <span className="section1expblue">rejuvenate</span> your
            body and calm your mind?
          </div>
          <div className="section6para pt-2">
            Take the first step towards ultimate relaxation. Book your
            appointment with us today.
          </div>
        </div>
        <div className="pe-md-4 pt-md-5 pt-2">
        <a href="#contact" className="testimonialbtn">
                <span className="pe-2">Book Appointment</span>{" "}
                <img
                  src={uprightarrow}
                  alt="arrow"
                  className="homeSegment6testimoialarrow"
                />
              </a>
        </div>
      </section>

      <section className="section7 p-md-5 p-3" id="contact">
        <div className="d-lg-flex p-md-5 contactformflex">
          <div className="slideLeft">
            <div className="contactheading">
              {" "}
              <span className="section1expblue">Connect </span> with Us Today!
            </div>
            <div className="contactsubheading py-3">
              We’d love to hear from you! Whether you have questions, need more
              information, or want to book a session, please contact us.
            </div>
            <div className="d-flex contacticoncontainer ">
              <div>
                <i class="fa-solid fa-envelope contacticon"></i>
              </div>
              <Link
                className="contactmail pt-1 ps-2"
                to={`mailto:admin@brookshealthandcare.com?subject=Interested%20In%20Services&body=Hello!%20I'm%20interested%20in...`}
              >
                admin@brookshealthandcare.com
              </Link>
            </div>
            <div className="py-4">
              <div className="d-flex contacticoncontainer ">
                <div>
                  <i class="fa-solid fa-phone contacticon"></i>
                </div>
                <div className="contactmail pt-1 ps-2"> <a className="tellink" href="tel:+441234954004">
                  01234954004
                </a></div>
              </div>
            </div>
            <div className="">
              <div className="d-flex contacticoncontainer ">
                <div>
                  <i class="fa-solid fa-location-dot contacticon"></i>{" "}
                </div>
                <div className="contactmail pt-1 ps-2">
                  Juniper walk, Kempston, Bedford. MK42 7SX
                </div>
              </div>
            </div>

            <div>
              <div className="section7heading pt-4">Office Hours</div>
              <div className="pb-2">
                <div className="section2missionbox p-3 rounded ">
                  <div className="section2mission">Monday - Friday</div>
                  <div className=" rounded section2missionpara p-3">
                    9:00 AM - 6:00 PM
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div className="section2missionbox p-3 rounded ">
                  <div className="section2mission"> Saturday</div>
                  <div className=" rounded section2missionpara p-3">
                    10:00 AM - 4:00 PM
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div className="section2missionbox p-3 rounded ">
                  <div className="section2mission">Sunday</div>
                  <div className=" rounded section2missionpara p-3">Closed</div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 pt-lg-1 slideRight">
            <form
              action="https://formspree.io/f/xjvnjbev"
              method="POST"
              className="contactform"
            >
              <div className="formgroup">
                <div className="formgroupsection pb-3 pb-md-1">
                  <label for="fname">First Name</label>
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder="Enter First Name"
                    className="mobileinput"
                  />
                </div>
                <div className="formgroupsection ">
                  <label for="lname">Last Name</label>
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    placeholder="Enter Last Name"
                    className="mobileinput"
                  />
                </div>
              </div>

              <div className="formgroupsection">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="emaill"
                  name="email"
                  placeholder="Enter Your Email "
                  className="maxwidth"
                />
              </div>
              <div className="formgroupsection pt-3">
                <label for="email">Phone</label>
                <input
                  type="phone"
                  id="phone"
                  name="phone"
                  placeholder="Enter Your Phone Number "
                  className="maxwidth"
                />
              </div>
              <div className="formgroupsection pt-3">
                <label for="message">Message</label>
                <textarea
                  className="messageform"
                  id="message"
                  name="message"
                  placeholder="Enter your Message here..."
                ></textarea>
              </div>
              <div className="contactformbtn pt-3">
                <button className="testimonialbtn footertestimonialbtn">
                  <span className="pe-2">Send Message</span>{" "}
                  <img
                    src={uprightarrow}
                    alt="arrow"
                    className="homeSegment6testimoialarrow footerhomeSegment6testimoialarrow"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
